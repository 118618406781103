import React from 'react';
import TrayectoriaContent from './TrayectoriaContent';

const Trayectoria = () => {
  return (
    <React.Fragment>
      <TrayectoriaContent />
    </React.Fragment>
  );
}

export default Trayectoria;
