import React from 'react';

const TrayectoriaCursos = () => {
  return (
    <div className="cv_data-item">
      <ul><span>CURSOS</span>
        <li>
          <div className="locale">
            Universidad Tecnológica Nacional.
          </div>
          <div className="dateInOut">
            {'2011'}
          </div>
          <div className="locale_title">
            Curso de “Electrónica Práctica Básica”, dictacto por Ing. Walter Cáseres.
          </div>
        </li>

        <li>
          <div className="locale">
            Universidad del Norte Santo Tomás de Aquino.
          </div>
          <div className="dateInOut">
            {'2014'}
          </div>
          <div className="locale_title">
            Taller “Ilustración Digital”, dictado por Tito Art.
          </div>
        </li>

        <li>
          <div className="locale">
            Curso de PHP y MySQL.
          </div>
          <div className="dateInOut">
            {'2016'}
          </div>
          <div className="locale_title">
            Curso teórico-práctico dictado por Cesar Cancino de Santiago de Chile.
          </div>
        </li>

        <li>
          <div className="locale">
            Plan111Mil Programadores.
          </div>
          <div className="dateInOut">
            {'2017'}
          </div>
          <div className="locale_title">
            Plan de gobierno para formación de futuros programadores.
          </div>
        </li>

        <li>
          <div className="locale">
            Udemy
          </div>
          <div className="dateInOut">
            {'2020'}
          </div>
          <div className="locale_title">
            Master en PHP, SQL, POO, MVC, Laravel, Symfony, WordPress +.
          </div>
        </li>

        <li>
          <div className="locale">
            Udemy
          </div>
          <div className="dateInOut">
            {'2020'}
          </div>
          <div className="locale_title">
            GIT+GitHub: Todo un sistema de control de versiones de cero
          </div>
        </li>

        <li>
          <div className="locale">
            Udemy
          </div>
          <div className="dateInOut">
            {'2020'}
          </div>
          <div className="locale_title">
            Aprende Javascript ES9, HTML, CSS3 y NodeJS desde cero
          </div>
        </li>
      </ul>
    </div>
  );
}

export default TrayectoriaCursos;
