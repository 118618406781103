import React from 'react';
import {Link} from 'react-router-dom';
import './navView.css';

const NavView = (props) => {
  const {anterior, siguiente} = props;
  return (
    <React.Fragment>
      <hr className="navView___hr" />
      <div className="container d-flex justify-content-md-between">
        {(anterior) ?
        <Link to={anterior} className="enlaces-proyects enlace-back">
          <i className="fas fa-angle-double-left"></i>
          <span className="ml-4">Anterior</span>
        </Link> : <div></div>}

        {(siguiente) ?
          <Link to={siguiente} className="enlaces-proyects enlace-next">
            <span className="mr-4">Siguiente</span>
            <i className="fas fa-angle-double-right"></i>
          </Link> : <div></div>
        }
      </div>
    </React.Fragment>
  );
}

export default NavView;
