import React from 'react';
import Nav from '../../../components/nav/Nav';
import Footer from '../../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './proyectoView.css';
import SobreElProyecto from './SobreElProyecto';
import TarjetasView from './TarjetasView';
import solucionaEso02 from '../../../images/proyectos/intro/03/02-solucionaeso.jpg';
import './ProyectoFonts.css';
import NavView from './NavView';

const ProyectoSolucionaEso = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      
      {/* Portada BG */}
      <div className="proyectoViewImg" style={{ background: "url("+solucionaEso02+")", backgroundRepeat: 'no-repeat' }}>
        <div className="proyectoView">

          {/* Navegación */}
          <Nav />

          {/* Título + Subtítulo */}
          <div className="headView_group">
            <h1>Soluciona Eso</h1>
            <h3>Proyecto Blog de solución de problemas varios y tecnología</h3>
          </div>

        </div> {/* -> proyectoView*/}

        {/* Tarjetas */}
        <TarjetasView 
          trabajo = 'Modificación de plantilla ^ Administración ^ Posteo'
          contexto = 'Blog sobre tecnología, tutoriales, etc.'
          nacimiento = '2012'
        />
        
        <SobreElProyecto
          subTitulo = 'Un Blog de Wordpress'
          mensaje = 'Se trata de un blog personal donde se aportan soluciones sobre diversos temas relacionados con la tecnología entre otras cosas.'
          mensaje2 = 'Está basado en el uso de Wordpress + Plugins, la plantilla fue modificada levemente para darle un toque de personalización, está enfocado a un target universal, es decir, cualquier individuo con un poco de curiosidad.'
          mensaje3 = ''
          boton = 'Visitar sitio'
          botonDisabled = ''
          urlBoton = 'https://solucionaeso.com/'
        />

        <NavView 
          anterior = 'proyecto-turnos-obras-sociales'
          siguiente = 'proyecto-fitness'
        />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default ProyectoSolucionaEso
