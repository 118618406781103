import React from 'react';
import Nav from '../../../components/nav/Nav';
import Footer from '../../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './proyectoView.css';
import SobreElProyecto from './SobreElProyecto';
import TarjetasView from './TarjetasView';
import DesignView from './DesignView';
import sap02 from '../../../images/proyectos/intro/01/02-sap.jpg';
import sap03 from '../../../images/proyectos/intro/01/03-sap.jpg';
import sap04 from '../../../images/proyectos/intro/01/04-sap.jpg';
import HeartSAP from '../../../images/svg/Heart-SAP.svg';
import BrandingView from './BrandingView';
import './ProyectoFonts.css';
import NavView from './NavView';

const ProyectoSapV1 = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      
      {/* Portada BG */}
      <div className="proyectoViewImg" style={{ background: "url("+sap02+")", backgroundRepeat: 'no-repeat' }}>
        <div className="proyectoView">

          {/* Navegación */}
          <Nav />

          {/* Título + Subtítulo */}
          <div className="headView_group">
            <h1>SAP</h1>
            <h3>Sistema de Administración Profesional v.1</h3>
          </div>

        </div> {/* -> proyectoView*/}

        {/* Tarjetas */}
        <TarjetasView 
          trabajo = 'Diseño ^ Desarrollo ^ Front-End ^ Back-End'
          contexto = 'Realizado en HTML + CSS + PHP + MySQL'
          nacimiento = '2007'
        />
        
        <SobreElProyecto
          subTitulo = '...Sobre el proyecto'
          mensaje = 'SAP surgió por la necesidad de colaborar con personal de la salud, brindando un servicio Online de administración, para llevar un seguimiento de sus pacientes con búsqueda inmediata de información de los mismos, controlar historial clínico, etc.'
          mensaje2 = '(Por motivos de seguridad y discreción, las imágenes del proyecto fueron distorsionadas)'
          mensaje3 = ''
          boton = 'Visitar sitio'
          botonDisabled = 'disabled'
        />

        {/* BRANDING */}
        <BrandingView
          preparacion = 'Este proyecto surgió para cubrir la necesidad de agilizar el trabajo de consultorio, aportando un beneficio directo al profesional de la salud y a sus pacientes, aportando orden, rapidez y seguridad en la información.'
          imgBrand = {HeartSAP}
          altBrand = 'SAP'
          tipografia1 = 'Nunito Bold'
          tipografia1Font = 'Nunito-Bold'
          tipografia2 = 'Roboto Regular'
          tipografia2Font = 'Roboto-Regular'
          color1 = '#870002'
          color2 = '#D9EDF7'
          color3 = '#F2DEDE'
          color4 = '#DFF0D8'
        />

        {/* DISEÑO */}
        <DesignView
          img1 = {sap03}
          alt1 = 'Planilla'

          img2 = {sap04}
          alt2 = 'Capita'

          img3 = ''
          alt3 = ''
        />

        <NavView 
          anterior = 'proyecto-fitness'
          siguiente = 'proyecto-turnos-obras-sociales'
        />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default ProyectoSapV1
