import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {useHistory} from 'react-router-dom';

const ContactoForm = () => {
  const form = useRef();
  const history = useHistory();

  const frmContact = { 
    userName: '', 
    userEmail:'', 
    userOrganization: '',
    userWeb: '', 
    emailTitle: 'Consulta Web - Portfolio',
    emailDetails:'' 
  };
  const [contact,setContact] = useState(frmContact);
  const handleChange = e => { 
  const {name,value} = e.target;
  setContact({...contact,[name]:value}); 
  };

  const handleSubmit = e =>{
     e.preventDefault();

    emailjs.sendForm('service_bp6xeyx', 'template_iveeh38', form.current, 'PMHeJmWVnktNkxIX2')

   .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          setContact(frmContact);
          history.push("/mailsuccess");
   }, (err) => {
          console.log('FAILED...', err);
          history.push("/mailerror");
   });
  }

  return (
    <div className="containerContact d-flex justify-content-center">

      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div className="container my-5">
        <h3 className="animate__animated animate__jello">
          Contacto
        </h3>
        <i className="fas fa-sort-down"></i>
          
        <form ref={form} onSubmit={handleSubmit}>
          
          <div className="form-row">
            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <input 
                type="text" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Tu Nombre"
                required
                value={contact.userName} 
                name="userName" 
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <input 
                type="email" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Tu Correo"
                required
                value={contact.userEmail} 
                name="userEmail" 
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <input 
                type="text" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Organización (Opcional)"
                value={contact.userOrganization}
                name="userOrganization"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <input 
                type="url" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Sitio web (Opcional)"
                value={contact.userWeb} 
                name="userWeb" 
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <textarea
              className="form-control animate__animated animate__backInUp"
              rows="5"
              value={contact.emailDetails} 
              name="emailDetails" 
              onChange={handleChange}
            ></textarea>
          </div>
          <button 
            type="submit" 
            className="btn btn-primary btn-block animate__animated animate__fadeInUp"
          >
            Enviar
          </button>
        </form>

        </div>
      </div>
    </div>
  );
}

export default ContactoForm;
