import React from 'react';
import Footer from '../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from '../../components/nav/Nav';
import './errors.css';
import Img404 from '../../images/svg/errors/404.svg';;

const Error404Content = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      <React.Fragment>
        {/* Cabecera + Navegación*/}
        <Nav />

        {/* Error404 */}
        <div className="container my-5 containerErrors">
          <h1>¡Página no encontrada!</h1>
          <h4>Estás buscando algo inadecuado o que no existe.</h4>
          <i className="fas fa-sort-down"></i>
          <img src={Img404} className="img-fluid imgError" alt="Error404"/>
          <hr/>
          <a href="/" className="btn btn-outline-secondary btn-lg">Regresar al sitio principal</a>
        </div>

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </React.Fragment>
    </div>
  )
}

export default Error404Content
