import React from 'react';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import AllProjectsList from '../../components/proyectos/all/AllProjectsList';

const AllProjects = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      <div>
        {/* Navegación*/}
        <Nav />

        {/* Listado de proyectos */}
        <AllProjectsList />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default AllProjects
