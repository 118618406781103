import React from 'react';

const TarjetasView = (props) => {

  const {trabajo, contexto, nacimiento} = props;

  return (
    <React.Fragment>
      <div className="container tarjetasProyectos align-items-center d-flex justify-content-center">
        <div className="row col-12">
      
          {/* Card 01 */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div className="card mt-5 d-flex">
              <h5 className="card-header align-items-center d-flex justify-content-center">
                TRABAJO
              </h5>
              <div className="card-body align-items-center d-flex justify-content-center">
                <div className="head_code">{trabajo}</div>
              </div>
            </div>
          </div>

          {/* Card 02 */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div className="card mt-5 d-flex">
              <h5 className="card-header align-items-center d-flex justify-content-center">
                CONTEXTO
              </h5>
              <div className="card-body align-items-center d-flex justify-content-center">
                <div className="head_code">{contexto}</div>                  
              </div>
            </div>
          </div>

          {/* Card 03 */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div className="card mt-5 d-flex">
              <h5 className="card-header align-items-center d-flex justify-content-center">
                NACIMIENTO
              </h5>
              <div className="card-body align-items-center d-flex justify-content-center">
                <div className="head_code">{nacimiento}</div>                  
              </div>
            </div>
          </div>

          </div> {/* -> row */}
      </div>
    </React.Fragment>
  );
}

export default TarjetasView;
