import React from 'react';
import Nav from '../../../components/nav/Nav';
import Footer from '../../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './proyectoView.css';
import SobreElProyecto from './SobreElProyecto';
import TarjetasView from './TarjetasView';
import DesignView from './DesignView';
import turnos02 from '../../../images/proyectos/intro/02/02-turnos.jpg';
import turnos03 from '../../../images/proyectos/intro/02/03-turnos.jpg';
import CalendarTurnos from '../../../images/svg/Calendar-turnos.svg';
import BrandingView from './BrandingView';
import './ProyectoFonts.css';
import NavView from './NavView';

const ProyectoTurnosObrasSociales = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      
      {/* Portada BG */}
      <div className="proyectoViewImg" style={{ background: "url("+turnos02+")", backgroundRepeat: 'no-repeat' }}>
        <div className="proyectoView">

          {/* Navegación */}
          <Nav />

          {/* Título + Subtítulo */}
          <div className="headView_group">
            <h1>Sistema de Turnos</h1>
            <h3>Un calendario virtual que permite al usuario administrar turnos de pacientes.</h3>
          </div>

        </div> {/* -> proyectoView*/}

        {/* Tarjetas */}
        <TarjetasView 
          trabajo = 'Diseño ^ Desarrollo ^ Front-End ^ Back-End'
          contexto = 'Realizado en HTML + CSS + PHP + MySQL'
          nacimiento = '2017'
        />
        
        <SobreElProyecto
          subTitulo = '...Sobre el proyecto'
          mensaje = 'Al percatarme de la necesidad de optimizar el trabajo de consultorio, decidí emprender proyectos como este, en el cual había que realizar una investigación sobre lo que en verdad se necesita para poder asignar turnos de manera completa, rápida y eficaz.'
          mensaje2 = ''
          mensaje3 = ''
          boton = 'Visitar sitio'
          botonDisabled = 'disabled'
        />

        {/* BRANDING */}
        <BrandingView
          preparacion = 'Este sistema pretende solucionar el inconveniente de organización de citas de pacientes con profesionales de la salud, apuntando directamente a la optimización y respuesta a la hora de asignar un turno y a su vez generando un impacto económico favorable para quien le de utilidad.'
          imgBrand = {CalendarTurnos}
          altBrand = 'Sistema de turnos'
          tipografia1 = 'Comfordata'
          tipografia1Font = 'Comfortaa-Regular'
          tipografia2 = 'Roboto Regular'
          tipografia2Font = 'Roboto-Regular'
          color1 = '#3D3D3D'
          color2 = '#008000'
          color3 = '#730101'
          color4 = '#467dab'
        />

        {/* DISEÑO */}
        <DesignView
          img1 = {turnos03}
          alt1 = 'Turnos'

          img2 = ''
          alt2 = ''

          img3 = ''
          alt3 = ''
        />

        <NavView 
          anterior = 'proyecto-sap-v1'
          siguiente = 'solucionaeso'
        />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default ProyectoTurnosObrasSociales
