import React from 'react';
import{Link}from 'react-router-dom';
import Lab01 from '../../images/lab/lab01.png';
import Lab02 from '../../images/lab/lab02.png';
import Lab03 from '../../images/lab/lab03.png';
import Lab04 from '../../images/lab/lab04.png';

const LabProyectosTarjetas = () => {
  return (
    <div className="container">
      <div className="row col-12 mx-auto">
      
      {/* Card 01 */}
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 my-3">
        <div className="card">
          <h5 className="card-header">
            Tablas de multiplicar<br />
            <div className="head_code">{'< NodeJS />'}</div>
          </h5>
          <div className="card-body">
            <a href="https://github.com/soriacarlos/curso-node-tabla" target="_blank" rel="noreferrer">
              <img src={Lab01} className="img-fluid" alt="Tablas de multiplicar" />
            </a>
          </div>
        </div>
      </div>

      {/* Card 02 */}
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 my-3">
        <div className="card">
          <h5 className="card-header">
            ToDo<br />
            <div className="head_code">{'< NodeJS />'}</div>
          </h5>
          <div className="card-body">
            <a href="https://github.com/soriacarlos/ToDo-NodeJS" target="_blank" rel="noreferrer">
              <img src={Lab02} className="img-fluid" alt="ToDO" />
            </a>
          </div>
        </div>
      </div>

      {/* Card 03 */}
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 my-3">
        <div className="card">
          <h5 className="card-header">
            mini Sistema Usuario
            <div className="head_code">{'< HTML5 + CSS3 + PHP + MySQL />'}</div>
          </h5>
          <div className="card-body">
            <Link to="/">
              <img src={Lab03} className="img-fluid" alt="ToDO" />
            </Link>
          </div>
        </div>
      </div>

      {/* Card 04 */}
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 my-3">
        <div className="card">
          <h5 className="card-header">
            App Clima
            <div className="head_code">{'< Node JS />'}</div>
          </h5>
          <div className="card-body">
            <a href="https://github.com/soriacarlos/Node-App-Clima" target="_blank" rel="noreferrer">
              <img src={Lab04} className="img-fluid" alt="ToDO" />
            </a>
          </div>
        </div>
      </div>

      </div> {/* -> row */}
    </div> // -> container
  );
}

export default LabProyectosTarjetas;
