import React from 'react';
import { Link } from 'react-router-dom';
import './proyectos.css';

const MasProyectos = () => {
  return (
    <div className="moreProjects">
      <div className="moreProjects_group">
        <h2>¿Quieres ver más?</h2>
        <div className="moreProjects_avatar img-fluid"></div>
        <Link to={'/Todos-mis-proyectos'} className="moreProjects_button">
          <button className="btn btn-outline-warning">
            Explorar todos mis proyectos
          </button>
        </Link>
      </div>
    </div>
  );
}

export default MasProyectos;
