import React from 'react';
import './branding.css';

const BrandingView = (props) => {

  const {
    preparacion, 
    imgBrand, 
    altBrand, 
    tipografia1, 
    tipografia1Font,
    tipografia2,
    tipografia2Font,
    color1, 
    color2, 
    color3, 
    color4
  } = props;

  return (
    <React.Fragment>
      <div className="container containerBranding mt-5">
        <h4>Branding</h4>
        <i className="fas fa-sort-down"></i>
        <div className="vertical-line-relative hide-responsive"></div>
        <div className="row border-hide-responsive d-flex justify-content-center">
          <div className="col-md-6 px-5">
            
            <div className="branding__title">Preparación</div>
            <div className="branding__info">
              <p>{preparacion}</p>
            </div>
          
          </div>

          <div className="col-md-6 vertical-line mx-auto">
            <img src={imgBrand} alt={altBrand} className="img-fluid branding___img" />
          </div>
        </div>

        <div className="row mt-5 py-5">
          <div className="col-md-6 col-md-4 px-5">
            <div className="branding__info text-center">
              <div className="branding__tipo_title" style={{ fontFamily: tipografia1Font }}>
                {tipografia1}
              </div>
              <div className="branding__tipo__description"  style={{ fontFamily: tipografia1Font }}>
              <p>A B C D E F G H I J K L<br />
              M N Ñ O P Q R S T U<br />
              V W X Y Z</p>
              <p>a b c d e f g h i j k l<br />
              m n ñ o p q r s t u<br />
              v w x y z</p>
              <p>á é í ó ú<br/>
              {'- + () [] {} * # $ % & / ='}</p>
              <p>0 1 2 3 4 5 6 7 8 9</p>
              </div>
            </div>
          </div>
          <div className="vertical-line-relative show-responsive"></div>
          <div className="col-md-6 vertical-line">
            <div className="branding__info text-center">
              <div className="branding__tipo_title" style={{ fontFamily: tipografia2Font }}>
                {tipografia2}
              </div>
              <div className="branding__tipo__description"  style={{ fontFamily: tipografia2Font }}>
              <p>A B C D E F G H I J K L<br />
              M N Ñ O P Q R S T U<br />
              V W X Y Z</p>
              <p>a b c d e f g h i j k l<br />
              m n ñ o p q r s t u<br />
              v w x y z</p>
              <p>á é í ó ú<br/>
              {'- + () [] {} * # $ % & / ='}</p>
              <p>0 1 2 3 4 5 6 7 8 9</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 d-flex justify-content-sm-around">
          {(color1) ? 
          <div className="branding__colors">
            <div style={{ background: color1 }} className="circles"></div>
            <span>{color1}</span>
          </div>
          : null }

          {(color2) ? 
          <div className="branding__colors">
            <div style={{ background: color2 }} className="circles"></div>
            <span>{color2}</span>
          </div>
          : null }

          {(color3) ? 
          <div className="branding__colors">
            <div style={{ background: color3 }} className="circles"></div>
            <span>{color3}</span>
          </div>
          : null }

          {(color4) ? 
          <div className="branding__colors">
            <div style={{ background: color4 }} className="circles"></div>
            <span>{color4}</span>
          </div>
          : null }
        </div>

      </div>
    </React.Fragment>
  );
}

export default BrandingView;
