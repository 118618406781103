import React from 'react';
import Nav from '../../../components/nav/Nav';
import Footer from '../../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './proyectoView.css';
import SobreElProyecto from './SobreElProyecto';
import TarjetasView from './TarjetasView';
import DesignView from './DesignView';
import fitness02 from '../../../images/proyectos/intro/04/02-fitness.jpg';
import fitness03 from '../../../images/proyectos/intro/04/03-fitness.jpg';
import fitness04 from '../../../images/proyectos/intro/04/04-fitness.jpg';
import fitness05 from '../../../images/proyectos/intro/04/05-fitness.jpg';
import BrandingView from './BrandingView';
import './ProyectoFonts.css';
import NavView from './NavView';

const ProyectoFitness = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      
      {/* Portada BG */}
      <div className="proyectoViewImg" style={{ background: "url("+fitness02+")", backgroundRepeat: 'no-repeat' }}>
        <div className="proyectoView">

          {/* Navegación */}
          <Nav />

          {/* Título + Subtítulo */}
          <div className="headView_group">
            <h1>Fitness</h1>
            <h3>Proyecto de seguimiento físico</h3>
          </div>

        </div> {/* -> proyectoView*/}

        {/* Tarjetas */}
        <TarjetasView 
          trabajo = 'Diseño ^ Desarrollo ^ Front-End ^ Back-End'
          contexto = 'Proyecto de vida Fitness'
          nacimiento = '2021'
        />
        
        <SobreElProyecto
          subTitulo = '..¿de qué se trata?'
          mensaje = 'Aplicación sencilla que permite al usuario realizar un seguimiento de sus rutinas, personalizando cada una en tarjetas llamativas e intuitivas.'
          mensaje2 = 'El sitio está integramente realizado en React JS + API REST en NodeJS con express + Firebase'
          mensaje3 = '(Esta App se encuentra en modo prototipo)'
          boton = 'Ver demo'
          botonDisabled = ''
          urlBoton = 'https://projectfitness.soriacarlos.com/'
        />

        {/* BRANDING */}
        <BrandingView
          preparacion = 'Proyecto fitness surgió a modo de referencia de un curso de React realizado a nivel básico con la explicación de componentes entre otras cosas, pero sentí la inquietud de mejorarlo, darle algo más de potencial, a partir de ese punto, mi mente explotó en posibles usos, implementaciones, ir más y más lejos. Actualmente, decidí presentar el prototipo para el día de mañana compararlo con la versión final completa en producción, un antes y un después.'
          imgBrand = {fitness03}
          altBrand = 'Proyecto Fitness'
          tipografia1 = 'Roboto Light'
          tipografia1Font = 'Roboto-Light'
          tipografia2 = 'Roboto Bold'
          tipografia2Font = 'Roboto-Bold'
          color1 = ''
          color2 = ''
          color3 = ''
          color4 = ''
        />

        {/* DISEÑO */}
        <DesignView
          img1 = {fitness04}
          alt1 = 'fitness04'

          img2 = {fitness05}
          alt2 = 'fitness05'

          img3 = ''
          alt3 = ''
        />

        <NavView 
          anterior = 'solucionaeso'
          siguiente = 'curso-sql'
        />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default ProyectoFitness
