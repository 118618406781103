import React from 'react';
import {Link} from 'react-router-dom';

const SocialFooter = () => {
  return (
    <div className="text-center">
      <div className="social-group">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com/in/soriacarlos/" className="social-circle" target="_blank" rel="noreferrer" title="LinkedIn">
          <i className="fab fa-linkedin-in"></i>
        </a>

        {/* GitHub */}
        <a href="https://github.com/soriacarlos" className="social-circle" target="_blank" rel="noreferrer" title="GitHub">
          <i className="fab fa-github"></i>
        </a>

        {/* Correo */}
        <Link to="/contacto" className="social-circle" title="¡Enviar un correo!">
          <i className="fas fa-envelope"></i>
        </Link>
          
      </div>
    </div>
  );
}

export default SocialFooter;