import React from 'react'

const InfoFooter = () => {
  return (
    <div className="text-center info-footer">
      <p>Sitio desarrollado en: <br />{'<ReactJS />'}</p>
      <p>&copy; Copyright 2021 - Todos los derechos reservados.</p>
    </div>
  )
}

export default InfoFooter
