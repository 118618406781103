import React from 'react';
import TrayectoriaSobreMi from './TrayectoriaSobreMi';
import TrayectoriaFormAcademica from './TrayectoriaFormAcademica';
import TrayectoriaIdiomas from './TrayectoriaIdiomas';
import TrayectoriaCursos from './TrayectoriaCursos';
import TrayectoriaConferencias from './TrayectoriaConferencias';
import TrayectoriaExperiencia from './TrayectoriaExperiencia';
import TrayectoriaSkills from './TrayectoriaSkills';

const TrayectoriaContent = () => {
  return (
    <div className="container containerTrayectoria my-4">
      <h3 className="animate__animated animate__jello">
        Trayectoria
      </h3>
      <i className="fas fa-sort-down"></i>
      <div className="avatarPic"></div>
      <div className="vertical-line-relative"></div>
      <div className="trayectoriaContentDiv">
        <h4>Soria Carlos Augusto</h4>
        <div className="vertical-line-relative hide-responsive"></div>
        <div className="horizontal-line-relative hide-responsive" style={{ width: '50%' }}></div>
        <div className="row">
          <div className="col-md-6 px-5">
            <TrayectoriaSobreMi />
          </div>
          <div className="col-md-6 px-5 vertical-line">
            <TrayectoriaFormAcademica />        
          </div>
        </div>
        <div className="horizontal-line-relative hide-responsive" style={{ width: '50%' }}></div>
        <div className="vertical-line-relative hide-responsive"></div> 
        <div className="iconCV">
          <i className="fas fa-globe-americas"></i>
        </div>
        <div className="cv_title">IDIOMAS</div>
        <div className="vertical-line-relative"></div> 
        <div className="row">
          <div className="col-md-12">
            <TrayectoriaIdiomas />
          </div>
        </div>
        <div className="vertical-line-relative hide-responsive"></div> 
        <div className="horizontal-line-relative hide-responsive" style={{ width: '50%' }}></div>
        <div className="row">
          <div className="col-md-6 px-5">
            <div className="vertical-line-relative"></div>
            <div className="iconCV">
              <i className="fas fa-graduation-cap"></i>
            </div>
            <div className="cv_title">CURSOS & CONFERENCIAS</div>
            <div className="vertical-line-relative"></div>
            <TrayectoriaCursos />
            <TrayectoriaConferencias />
          </div>
          <div className="col-md-6 px-5 vertical-line">
          <div className="vertical-line-relative"></div>
          <div className="iconCV">
            <i className="fas fa-laptop-house"></i>
          </div>
          <div className="cv_title">EXPERIENCIA</div>
          <div className="vertical-line-relative"></div>
            <TrayectoriaExperiencia />      
          </div>
        </div>
        <div className="vertical-line-relative"></div> 
        <div className="iconCV">
          <i className="fas fa-brain"></i>
        </div>
        <div className="cv_title">OTROS CONOCIMIENTOS</div>
        <div className="vertical-line-relative hide-responsive"></div> 
        <div className="row">
          <div className="col-md-12">
            <TrayectoriaSkills />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrayectoriaContent;
