import React from 'react';
import LabProyectosTarjetas from './LabProyectosTarjetas';

const LabProyectos = () => {
  return (
    <div className="container lab">
      <h2 className="proyectos_h2">Experimentos de laboratorio</h2>
      <div className="icon_lab animate__animated animate__swing animate__infinite"></div>
      <h3>¡Desarrollar APPs es divertido!</h3>

      <LabProyectosTarjetas />

    </div> // -> container lab
  );
}

export default LabProyectos;
