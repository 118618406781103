import React from 'react';

const TrayectoriaExperiencia = () => {
  return (
    <div className="cv_data-item">
      <ul>

        {/* Syloper S.R.L. */}
        <li>
          <div className="locale">
            Syloper S.R.L.
          </div>
          <div className="dateInOut">
            {'2023 > Actualidad'}
          </div>
          <div className="adress">
            Argentina • Rosario • Remoto
          </div>
          <div className="locale_title">
            <p>Desarrollador backend PHP:</p>
            <ul>
              <li>HTML5, CCS3, Javascript, PHP Laravel, Cakephp.</li>
            </ul>
          </div>
        </li>
        {/* .Syloper S.R.L. */}

        {/* Sitenso SA by Tensolite */}
        <li>
          <div className="locale">
            Sitenso SA by Tensolite
          </div>
          <div className="dateInOut">
            {'2021 > 2023'}
          </div>
          <div className="adress">
            Ruta Nac. 9 km 1298 • Tucumán • Tafí Viejo.
          </div>
          <div className="locale_title">
            <p>Me desempeño como desarrollador FullStack/DevOps, trabajando en equipo cada día llevando adelante proyectos
              internos y externos, basados en requerimientos con metodología SCRUM, implementación y organización, destaco
              el manejo de las siguientes herramientas:</p>
            <ul>
              <li>HTML5, CCS3, Javascript, ReactJS, NodeJS, PHP Laravel, Docker, Jenkins.</li>
              <li>Configuración e implementación de servidores Linux.</li>
              <li>Nginx, Nginx-Proxy, Apache2, Dominios, FTP, SSH, MySQL, MongoDB</li>
            </ul>
          </div>
        </li>
        {/* .Sitenso SA by Tensolite */}

        {/* CENTRO DE COMPRAS GÓMEZ PARDO */}
        <li>
          <div className="locale">
            CENTRO DE COMPRAS GÓMEZ PARDO
          </div>
          <div className="dateInOut">
            {'2018 > 2020'}
          </div>
          <div className="adress">
            Diagonal Lechesi Km. 3,5 • Tucumán • Tafí Viejo.
          </div>
          <div className="locale_title">
            Sector de Sistemas, área técnica-informática, control y prevención de errores, testing de soporte de DLR Software, soporte área de facturación.
          </div>
        </li>
        {/* .CENTRO DE COMPRAS GÓMEZ PARDO */}

        {/* COMPUTACIÓN */}
        <li>
          <div className="locale">
            COMPUTACIÓN
          </div>
          <div className="dateInOut">
            {'2007 > 2008'}
          </div>
          <div className="adress">
            Av. Belgrano 2800 • Tucumán • San Miguel de Tucumán.
          </div>
          <div className="locale_title">
            Services Técnico de PC y Diseñador Web.
            <ul>
              <li>Desarrollo de base de datos Access para Fuerza Republicana.</li>
              <li>Diseño Web para SuperMercado de la Cocha.</li>
            </ul>
          </div>
        </li>
        {/* .COMPUTACIÓN */}

        {/* FREELANCE */}
        <li>
          <div className="locale">
            FREELANCE
          </div>
          <div className="dateInOut">
            {'2005 > Actualidad'}
          </div>
          <div className="locale_title">
            Diseño y Desarrolo Web con manejo de Bases de datos.
          </div>
        </li>
        {/* .FREELANCE */}




      </ul>
    </div>
  );
}

export default TrayectoriaExperiencia;
