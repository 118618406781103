import React from 'react';
import {Link} from 'react-router-dom';

const ContactFooter = () => {
  return (
    <div className="text-center contact-footer">
      <h2 className="subheader_h2">¿Contactar?</h2>

      <Link to="/contacto" className="btn btn-outline-warning">
        Enviarme un mensaje
      </Link>
      
    </div>
  );
}

export default ContactFooter;
