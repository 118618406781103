import React from 'react';
import Modal from "react-bootstrap/Modal";
import {gsap} from 'gsap';
import {TextPlugin} from 'gsap/TextPlugin';
import {Link} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import SobreMi from './SobreMi';

const InfoHeader = () => {

  const [isOpen, setIsOpen] = React.useState(false);
  
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const modalLoaded = () => {
    
  };

  const headerGsap = React.createRef()
  const subHeaderGsap = React.createRef()
  
  React.useEffect(() => {
    gsap.to(headerGsap.current, {color: '#FFC107', duration: 2})
    gsap.to(subHeaderGsap.current, {
      duration: 3,
      text: {
        value: "Software Developer",
      },
      ease: "power5"
    });
  
    gsap.registerPlugin(TextPlugin)
  }, [headerGsap, subHeaderGsap])

  return (
    <React.Fragment>
      <div className="info-header">
        
        <h1 className="animate__animated animate__bounceInLeft">
          <div className="animate__animated animate__jello animate__delay-1s">
            <Link to="/trayectoria" ref={headerGsap}>
              Soria Carlos
            </Link>
          </div>
        </h1>

        <h3 ref={subHeaderGsap}>
          #$%&@#$%&@#$%&@#$%
        </h3>
        <br />
        <button
          to="/aboutme"
          className="btn btn-outline-warning"
          onClick={showModal}
        >
          Un poco de mi
        </button>
        <div className="text-center">
          <Modal show={isOpen} onHide={hideModal} onEntered={modalLoaded}>
            <Modal.Body>
              <SobreMi />
            </Modal.Body>
            <Modal.Footer>
              <button onClick={hideModal} className="btn btn-danger btn-sm">Cerrar</button>
            </Modal.Footer>
          </Modal>
        </div>
        
      </div>
      <div className="header__arrowDown">
        <span>Proyectos</span>
        <br/>
        <AnchorLink href="#AnchorProyectos">
          <i className="fas fa-angle-double-down"></i>
        </AnchorLink >
      </div>
    </React.Fragment>
  )
}

export default InfoHeader;
