import React from 'react';

const SobreElProyecto = (props) => {
  const {subTitulo, mensaje, mensaje2, mensaje3, boton, botonDisabled, urlBoton} = props;
  
  return (
    <React.Fragment>
      {/* Sobre el proyecto */}
      <div className="container-fluid sobreElProyecto">
        <div className="container">
          <h2 className="subheader_h2">{subTitulo}</h2>
          <hr />
          {(mensaje) ? <p>{mensaje}</p> : null}
          {(mensaje2) ? <p>{mensaje2}</p> : null}
          {(mensaje3) ? <p>{mensaje3}</p> : null}
          <br/>
          {botonDisabled === 'disabled' ?
            <button className="btn btn-outline-warning" disabled>{boton}</button>
            :
            <a href={urlBoton} target="_blank" rel="noreferrer">
              <button className="btn btn-outline-warning">{boton}</button>
            </a>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default SobreElProyecto;
