import React from 'react';
import {Link} from 'react-router-dom';
import './allProjectsList.css';
import sap00 from '../../../images/proyectos/intro/01/00-sap.jpg';
import turnos00 from '../../../images/proyectos/intro/02/00-turnos.jpg';
import SolucionaEso00 from '../../../images/proyectos/intro/03/00-solucionaeso.jpg';
import fitness00 from '../../../images/proyectos/intro/04/00-fitness.jpg';
import Sql00 from '../../../images/proyectos/all/curso-sql/01-111mil-sql.jpg';

const AllProjectsList = () => {
  return (
    <div className="container containerAllProject my-4 pt-5">
      <h3 className="animate__animated animate__jello">
        Todos mis proyectos
      </h3>
      <i className="fas fa-sort-down"></i>
      <div className="contentAllProject">
      
        <div className="oneProject">
          <Link to="/proyecto-sap-v1">
            <div
            style={{ 
              background: 'url('+sap00+')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}></div>
            <div><h4>SAP<br />Sistema de Administración</h4></div>
          </Link>
        </div>

        <div className="oneProject">
          <Link to="/proyecto-turnos-obras-sociales">
            <div
            style={{ 
              background: 'url('+turnos00+')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}></div>
            <div><h4>Turnos Online</h4></div>
          </Link>
        </div>

        <div className="oneProject">
          <Link to="/solucionaeso">
            <div
            style={{ 
              background: 'url('+SolucionaEso00+')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}></div>
            <div><h4>SolucionaESO - Wordpress</h4></div>
          </Link>
        </div>

        <div className="oneProject">
          <Link to="/proyecto-fitness">
            <div
            style={{ 
              background: 'url('+fitness00+')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}></div>
            <div><h4>Proyecto Fitness</h4></div>
          </Link>
        </div>

        <div className="oneProject">
          <Link to="/curso-sql">
            <div
            style={{ 
              background: 'url('+Sql00+')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}></div>
            <div><h4>Curso SQL</h4></div>
          </Link>
        </div>

      </div>
    </div>
  );
}

export default AllProjectsList;
