import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import Proyectos from '../../components/proyectos/Proyectos';
import MasProyectos from '../../components/proyectos/MasProyectos';
import LabProyectos from '../../components/proyectos/LabProyectos';

const PrincipalContent = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      <React.Fragment>
        {/* Cabecera + Navegación*/}
        <Header />

        {/* Listado de proyectos */}
        <Proyectos />

        {/* Más Proyectos */}
        <MasProyectos />

        {/* Laboratorio */}
        <LabProyectos />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </React.Fragment>
    </div>
  )
}

export default PrincipalContent
