import React from 'react';

const TrayectoriaIdiomas = () => {
    return (
        <div className="cv_p">
            <p><b>Español Nativo</b></p>
            <p><b>Inglés A2 • ATICANA</b></p>
        </div>
    );
}

export default TrayectoriaIdiomas;
