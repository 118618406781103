import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import PrincipalContent from './contents/principal/PrincipalContent';
import ProyectoSapV1 from './components/proyectos/view/ProyectoSapV1';
import ProyectoTurnosObrasSociales from './components/proyectos/view/ProyectoTurnosObrasSociales';
import ProyectoSolucionaEso from './components/proyectos/view/ProyectoSolucionaEso';
import ProyectoFitness from './components/proyectos/view/ProyectoFitness';
import AllProjects from './contents/all-projects/AllProjects';
import CursoSQL from './components/proyectos/view/CursoSQL';
import TrayectoriaContent from './contents/trayectoria/TrayectoriaContent';
import ContactoContent from './contents/contacto/ContactoContent';
import MailError from './contents/contacto/MailError';
import MailSuccess from './contents/contacto/MailSuccess';
import Error404 from './contents/errors/Error404';

const App = () => {
  return (
    <Router>
      <Switch>

        <Route component={PrincipalContent} exact path='/' />
        <Route component={PrincipalContent} path='/principal' />
        <Route component={ProyectoSapV1} path='/proyecto-sap-v1' />
        <Route component={ProyectoTurnosObrasSociales} path='/proyecto-turnos-obras-sociales' />
        <Route component={ProyectoSolucionaEso} path='/solucionaeso' />
        <Route component={ProyectoFitness} path='/proyecto-fitness' />
        <Route component={AllProjects} path='/Todos-mis-proyectos' />
        <Route component={CursoSQL} path='/curso-sql' />
        <Route component={TrayectoriaContent} path='/trayectoria' />
        <Route component={ContactoContent} path='/contacto' />
        <Route component={MailSuccess} path='/mailsuccess' />
        <Route component={MailError} path='/mailerror' />
        <Route component={Error404} />
        
      </Switch>
    </Router>
  );
};

export default App;