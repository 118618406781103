import React from 'react';
import './proyectos.css';
import ProyectosIntro from './ProyectosIntro';

const Proyectos = () => {

  return (
    <React.Fragment>
      <div className="container proyectos" id="AnchorProyectos">
        {/* Título de introducción */}
        <h2 className="proyectos_h2 animate__animated animate__jello">Proyectos</h2>
        <i className="fas fa-sort-down"></i>

        {/* Listado de proyectos */}
        <ProyectosIntro />

      </div> {/* -> container proyectos */}
    </React.Fragment>
  );
}

export default Proyectos;
