import React from 'react';

const TrayectoriaConferencias = () => {
  return (
    <div className="cv_data-item">
      <ul><span>CONFERENCIAS</span>
        <li>
          <div className="locale">
            Universidad Tecnológica Nacional.
          </div>
          <div className="dateInOut">
            {'2010'}
          </div>
          <div className="adress">
            Colombia 2337 • Tucumán • San Miguel de Tucumán.
          </div>
          <div className="locale_title">
            "Intel, nuevas tecnologías"
          </div>
        </li>
      </ul>
    </div>
  );
}

export default TrayectoriaConferencias;
