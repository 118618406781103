import React from 'react';
import AfterEffects from '../../images/cv/skills/afterEffects.svg';
// import AngularJS from '../../images/cv/skills/Angular.svg';
// import VueJS from '../../images/cv/skills/Vue.svg';
import Corel from '../../images/cv/skills/corel.svg';
import Css3 from '../../images/cv/skills/css3.svg';
import Github from '../../images/cv/skills/github.svg';
import Html5 from '../../images/cv/skills/html5.svg';
import Illustrator from '../../images/cv/skills/illustrator.svg';
import Indesign from '../../images/cv/skills/indesign.svg';
import Js from '../../images/cv/skills/js.svg';
import Mysql from '../../images/cv/skills/mysql.svg';
import Photoshop from '../../images/cv/skills/photoshop.svg';
import Php from '../../images/cv/skills/php.svg';
import Laravel from '../../images/cv/skills/laravel.svg';
import Postman from '../../images/cv/skills/postman.svg';
import Premiere from '../../images/cv/skills/premiere.svg';
import ReactJS from '../../images/cv/skills/React.svg';
import NodeJS from '../../images/cv/skills/nodejs.svg';
import Wordpress from '../../images/cv/skills/wordpress.svg';
import MongoDB from '../../images/cv/skills/mongoDB.svg';
import Firebase from '../../images/cv/skills/firebase.svg';
import Trello from '../../images/cv/skills/Trello.svg';
import VisualStudioCode from '../../images/cv/skills/visualStudioCode.svg';
import Cinema4d from '../../images/cv/skills/cinema4d.svg';
import Ubuntu from '../../images/cv/skills/ubuntu.svg';
import Apache from '../../images/cv/skills/apache.svg';
import Nginx from '../../images/cv/skills/nginx.svg';
import Docker from '../../images/cv/skills/docker.svg';
import Jenkins from '../../images/cv/skills/jenkins.svg';
import Jira from '../../images/cv/skills/jira.svg';
import PHPStorm from '../../images/cv/skills/phpstorm.svg';
import Webstorm from '../../images/cv/skills/webstorm.svg';
import Notion from '../../images/cv/skills/notion.svg';

const TrayectoriaSkills = () => {
        return (
            <div className="cv_skills">
                    <img
                        src={Html5}
                        className="img-fluid"
                        alt="HTML 5"
                        title="HTML 5"
                    />
                    <img
                        src={Css3}
                        className="img-fluid"
                        alt="CSS 3"
                        title="CSS 3"
                    />
                    <img
                        src={Js}
                        className="img-fluid"
                        alt="JavaScript"
                        title="JavaScript"
                    />
                    <img
                        src={Php}
                        className="img-fluid"
                        alt="PHP 7"
                        title="PHP 7"
                    />
                    <img
                        src={Laravel}
                        className="img-fluid"
                        alt="Laravel"
                        title="Laravel"
                    />
                    <img
                        src={Postman}
                        className="img-fluid"
                        alt="Postman"
                        title="Postman"
                    />
                    <img
                        src={Wordpress}
                        className="img-fluid"
                        alt="Wordpress"
                        title="Wordpress"
                    />
                    <img
                        src={ReactJS}
                        className="img-fluid"
                        alt="React JS"
                        title="React JS"
                    />
                    <img
                        src={NodeJS}
                        className="img-fluid"
                        alt="Node JS"
                        title="Node JS"
                    />
                    <img
                        src={Github}
                        className="img-fluid"
                        alt="Github"
                        title="Github"
                    />
                    <img
                        src={Mysql}
                        className="img-fluid"
                        alt="MySQL"
                        title="MySQL"
                    />
                    <img
                        src={MongoDB}
                        className="img-fluid"
                        alt="MondoDB"
                        title="MondoDB"
                    />
                    <img
                        src={Firebase}
                        className="img-fluid"
                        alt="Firebase"
                        title="Firebase"
                    />
                    <img
                        src={Ubuntu}
                        className="img-fluid"
                        alt="Ubuntu"
                        title="Ubuntu"
                    />
                    <img
                        src={Apache}
                        className="img-fluid"
                        alt="Apache"
                        title="Apache"
                    />
                    <img
                        src={Nginx}
                        className="img-fluid"
                        alt="Nginx"
                        title="Nginx"
                    />
                    <img
                        src={Docker}
                        className="img-fluid"
                        alt="Docker"
                        title="Docker"
                    />
                    <img
                        src={Jenkins}
                        className="img-fluid"
                        alt="Jenkins"
                        title="Jenkins"
                    />
                    <img
                        src={Jira}
                        className="img-fluid"
                        alt="Jira"
                        title="Jira"
                    />
                    <img
                        src={Trello}
                        className="img-fluid"
                        alt="Trello"
                        title="Trello"
                    />
                    <img
                        src={PHPStorm}
                        className="img-fluid"
                        alt="PHPStorm"
                        title="PHPStorm"
                    />
                    <img
                        src={Webstorm}
                        className="img-fluid"
                        alt="Webstorm"
                        title="Webstorm"
                    />
                    <img
                        src={VisualStudioCode}
                        className="img-fluid"
                        alt="Visual Studio Code"
                        title="Visual Studio Code"
                    />
                    <img
                        src={Notion}
                        className="img-fluid"
                        alt="Notion"
                        title="Notion"
                    />
                    <img
                        src={Indesign}
                        className="img-fluid"
                        alt="Adobe Indesign"
                        title="Adobe Indesign"
                    />
                    <img
                        src={Illustrator}
                        className="img-fluid"
                        alt="Adobe Illustrator"
                        title="Adobe Illustrator"
                    />
                    <img
                        src={Photoshop}
                        className="img-fluid"
                        alt="Adobe Photoshop"
                        title="Adobe Photoshop"
                    />
                    <img
                        src={Corel}
                        className="img-fluid"
                        alt="CorelDraw"
                        title="CorelDraw"
                    />
                    <img
                        src={Premiere}
                        className="img-fluid"
                        alt="Adobe Premiere"
                        title="Adobe Premiere"
                    />
                    <img
                        src={AfterEffects}
                        className="img-fluid"
                        alt="Affter Effects"
                        title="Affter Effects"
                    />
                    <img
                        src={Cinema4d}
                        className="img-fluid"
                        alt="Cinema 4D"
                        title="Cinema 4D"
                    />
            </div>
        );
}

export default TrayectoriaSkills;
