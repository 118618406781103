import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import './nav.css';
import Logo from '../../images/svg/isologo/isologo_cs_negative.svg';
import LogoResponsive from '../../images/svg/isologo/isologo_cs.svg';

const Nav = () => {

  const [navResponsive, setNavResponsive] = React.useState(true)

  const mostrarNavResponsive = () => {
    if(navResponsive){
      document.getElementById('navResponsiveId').style.left = 0
      setNavResponsive(false)
    }else{
      document.getElementById('navResponsiveId').style.left = '-300px'
      setNavResponsive(true)
    }
  }

  return (
    <div className="container-fluid">

      <div 
        className="btn-responsive" 
        onClick={() => mostrarNavResponsive(setNavResponsive(!navResponsive))}
      >
        <i className="fas fa-bars"></i>
      </div>

      {/* Nav Responsive */}
      <div 
        id="navResponsiveId"
        className="nav-responsive"
        style={{ 
          left: '-300px'
         }}
      >
        <ul>
          <li>
            <img src={LogoResponsive} alt="Logo" className="navbar-brand" style={{
              width: '50%'
            }}/>
          </li>
          <li>
            <Link to="/">
              <i className="fas fa-home"></i>&nbsp; Principal
            </Link>
          </li>
          <li>
            <Link to="/Todos-mis-proyectos">
            <i className="fas fa-project-diagram"></i>&nbsp; Proyectos
            </Link>
          </li>
          <li>
            <Link to="/trayectoria">
            <i className="fas fa-chart-line"></i>&nbsp; Trayectoria
            </Link>
          </li>
          <li>
            <Link to="/contacto">
            <i className="fas fa-envelope"></i>&nbsp; Contacto
            </Link>
          </li>
        </ul>
      </div>
      {/* ./Nav Responsive */}

      <div className="navegation">
      <div className="nav-logo">
        <img src={Logo} className="latir" alt=""/>
      </div>
      <div className="nav-group">
        <ul>
          <li>
            <NavLink to="/principal" className="cool-link">
              <i className="fas fa-home"></i>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Todos-mis-proyectos" className="cool-link">
              Proyectos
            </NavLink>
          </li>
          <li>
            <NavLink to="/trayectoria" className="cool-link">
              Trayectoria
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacto" className="cool-link">
              Contactar
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
}

export default Nav;
