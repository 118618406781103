import React from 'react';
import {Link} from 'react-router-dom';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './contacto.css';
import MailErrorImg from '../../images/mail/Mail_error.svg';

const MailError = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      <div>
        {/* Navegación*/}
        <Nav />

        <div className="containerContact d-flex justify-content-lg-center">
          <div className="col-lg-8">
            <div className="mail_success container my-3">
            <h3 className="animate__animated animate__jello">
              Contacto
            </h3>
            <i className="fas fa-sort-down"></i>
              <div className="d-flex justify-content-center">
                <img src={MailErrorImg} alt="Enviado!"/>
              </div>

                <h4 className="text-center text-primary">¡Oops!</h4>
                <p className="text-center">¡Lo lamento, ocurrió un error inesperado!</p>
                <p className="text-center">Por favor, intenta nuevamente</p>
                
                <Link to="/contacto" className="d-flex justify-content-center">
                  <button type="button" className="btn btn-primary">
                    Volver a Contacto
                  </button>
                </Link>


            </div>
          </div>
        </div>

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  );
}

export default MailError;
