import React from 'react';
import './design.css';

const DesignView = (props) => {

  const {img1, alt1, img2, alt2, img3, alt3} = props;

  return (
    <React.Fragment>
      <div className="vertical-line-relative"></div>
      <div className="container containerDesign mt-5">
        <h4>Diseño</h4>
        <i className="fas fa-sort-down"></i>
        <div className="vertical-line-relative hide-responsive"></div>
        {(img1) ? <img src={img1} className="img-fluid d-flex justify-content-center" alt={alt1} /> : null}
        <br/>
        {(img2) ? <img src={img2} className="img-fluid d-flex justify-content-center" alt={alt2} /> : null}
        <br/>
        {(img3) ? <img src={img3} className="img-fluid d-flex justify-content-center" alt={alt3} /> : null}
      </div>
    </React.Fragment>
  );
}

export default DesignView;
