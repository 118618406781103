import React from 'react'
import Particles from 'react-particles-js';
import Nav from '../nav/Nav'
import InfoHeader from './InfoHeader'
import './header.css';

const Header = () => {
  return (
    <div className="container-fluid">
      <div className="contenedor-header">
      <Particles
            params={{
              "particles": {
                  "number": {
                      "value": 50
                  },
                  "size": {
                      "value": 3,
                      "random": true,
                      "anim": {
                        "speed": 8,
                        "size_min": 0.3
                    }
                  },
                  "line_linked": {
                    "enable": true
                },
                "move": {
                  "random": true,
                  "speed": 1,
                  "direction": "top",
                  "out_mode": "out"
              }
              },
              "interactivity": {
                  "events": {
                      "onhover": {
                          "enable": true,
                          "mode": "repulse"
                      },
                  },             
              },          
          }} />
        
          <Nav />
          <InfoHeader />

      </div>
    </div>
  )
}

export default Header
