import React from 'react';
import {Link} from 'react-router-dom';
import proyecto01 from '../../images/proyectos/intro/01/01-sap.jpg';
import proyecto02 from '../../images/proyectos/intro/02/01-turnos.jpg';
import proyecto03 from '../../images/proyectos/intro/03/01-solucionaeso.jpg';
import proyecto04 from '../../images/proyectos/intro/04/01-fitness.jpg';

const ProyectosIntro = () => {
  return (
    <React.Fragment>
      {/* Proyecto 01 */}
      <div className="proyecto-intro">
      <div className="num-proyecto">01</div>
        <img src={proyecto01} alt="SAP v1"/>
        <div className="proyecto-intro-contenido">
          <h3>SAP v1</h3>
          <p>Sistema de Administración Profesional de la Salud</p>
          <Link to={'/proyecto-sap-v1'}>
            <button className="btn btn-outline-warning">Explorar</button>
          </Link>
        </div>
      </div>

      {/* Proyecto 02 */}
      <div className="proyecto-intro">
      <div className="num-proyecto">02</div>
        <img src={proyecto02} alt="Sistema de turnos para obras sociales"/>
        <div className="proyecto-intro-contenido">
          <h3>Sistema de turnos para obras sociales</h3>
          <p>Un calendario virtual que permite al usuario administrar turnos de pacientes.</p>
          <Link to={'/proyecto-turnos-obras-sociales'}>
            <button className="btn btn-outline-warning">Explorar</button>
          </Link>
        </div>
      </div>

      {/* Proyecto 03 */}
      <div className="proyecto-intro">
      <div className="num-proyecto">03</div>
        <img src={proyecto03} alt="SolucionaEso"/>
        <div className="proyecto-intro-contenido">
          <h3>SolucionaEso</h3>
          <p>Un blog donde se aportan soluciones sobre diversos temas relacionados con la tecnología entre otras cosas.</p>
          <Link to={'/solucionaeso'}>
            <button className="btn btn-outline-warning">Explorar</button>
          </Link>
        </div>
      </div>

      {/* Proyecto 04 */}
      <div className="proyecto-intro">
      <div className="num-proyecto">04</div>
        <img src={proyecto04} alt="Proyecto Fitness"/>
        <div className="proyecto-intro-contenido">
          <h3>Proyecto Fitness</h3>
          <p>Prototipo de APP para realizar ejercicios.</p>
          <Link to={'/proyecto-fitness'}>
            <button className="btn btn-outline-warning">Explorar</button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProyectosIntro;
