import React from 'react';


const TrayectoriaDownloadCV = () => {
  return (
    <div className="container my-4">
      <h3>Descargar Curriculum Vitae</h3>
      <hr/>
        <a href="https://soriacarlos.com/cv/cv_soria-carlos-developer-fullstack.pdf" target="_blank" rel="noreferrer">
        <button
          type="button"
          className="btn btn-outline-warning btn-lg"
        >
          <i className="far fa-file-pdf"></i>&nbsp;
          Descargar
        </button>
      </a>
    </div>
  );
}

export default TrayectoriaDownloadCV;