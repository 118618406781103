import React from 'react';
import ContactFooter from './ContactFooter';
import SocialFooter from './SocialFooter';
import InfoFooter from './InfoFooter';
import './footer.css';
import Logo from '../../images/svg/isologo/isologo_cs_negative.svg';

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="contenedor-footer">

        {/* <div className="container"> */}
        <div className="footer_group">
          {/* Contacto */}
          <ContactFooter />
          <hr />
          {/* Social */}
          <SocialFooter />
          {/* Logo */}
          <div className="logo-footer">
            <img src={Logo} alt="Logo"/>
          </div>
          {/* Correo */}
          <InfoFooter />
        </div>
        
      </div>
    </div>
  );
}

export default Footer;
