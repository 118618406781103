import React from 'react';

const TrayectoriaFormAcademica = () => {
  return (
      <React.Fragment>
        <div className="vertical-line-relative"></div>
        <div className="iconCV">
          <i className="fas fa-user-graduate"></i>
        </div>

        <div className="cv_title">FORMACIÓN ACADÉMICA</div>
        <div className="vertical-line-relative"></div>
        <div className="cv_data-item">

        <div className="cv_data-item">
          <ul><span>UNIVERSITARIO</span>
            <li>
              <div className="locale">
                Universidad Tecnológica Nacional.
              </div>
              <div className="dateInOut">
                {'2009 > 2012'}
              </div>
              <div className="adress">
                Rivadavia 1050 • Tucumán • San Miguel de Tucumán.
              </div>
              <div className="locale_title">
                "Carrera de Ingeniaría Electrónica"
              </div>
            </li>

            <li>
              <div className="locale">
                Universidad del Norte Santo Tomás de Aquino
              </div>
              <div className="dateInOut">
                {'2013 > 2016'}
              </div>
              <div className="adress">
                Campus UNSTA • Av. Juan Domingo Perón 2085 • Tucumán • Yerba Buena
              </div>
              <div className="locale_title">
                "Licenciatura En Diseño Gráfico."
              </div>
            </li>
          </ul>
        </div>


          <ul><span>SECUNDARIO</span>
            <li>
              <div className="locale">
                Instituto Nuestra Señora de Montserrat.
              </div>
              <div className="dateInOut">
                {'2003 > 2009'}
              </div>
              <div className="adress">
                Colombia 2337 • Tucumán • San Miguel de Tucumán.
              </div>
              <div className="locale_title">
                "Educación Polimodal con modalidad en Economía y Gestión de las Organizaciones."
              </div>
            </li>
          </ul>
        </div>


        <div className="vertical-line-relative"></div>
      </React.Fragment>
  );
}

export default TrayectoriaFormAcademica;
