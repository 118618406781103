import React from 'react';

const TrayectoriaSobreMi = () => {
  return (
    <React.Fragment>
      <div className="vertical-line-relative"></div>
      <div className="iconCV">
        <i className="fas fa-child"></i>
      </div>
      <div className="cv_title">SOBRE MI</div>
      <div className="vertical-line-relative"></div>
      <div className="cv_p">
          <p>Nacido un 27 de enero de 1990.</p>
          <p>Desarrollador Web FullStack, apasionado del mundo Backend de PHP, bases de datos y servidores web, con
              experiencia en frontend y su lógica correspondiente, en especial de ReactJS.</p>
          <p>Además, soy Licenciando en Diseño Gráfico, con formación en las distintas ramas que corresponden tanto al
              Diseño Gráfico como al Diseño Multimedia.</p>
      </div>
      <div className="vertical-line-relative"></div>
      <div className="cv_p">
        <p>Disponibilidad: <b>Full-Time</b></p>
      </div>
      <div className="vertical-line-relative hide-responsive "></div>
      <div className="cv_p">
        <p>Estado Civil: <b>Soltero</b></p>
      </div>
      <div className="vertical-line-relative hide-responsive"></div>
    </React.Fragment>
  );
}

export default TrayectoriaSobreMi;
