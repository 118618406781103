import React from 'react';
import Nav from '../../../components/nav/Nav';
import Footer from '../../../components/footer/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import './proyectoView.css';
import SobreElProyecto from './SobreElProyecto';
import TarjetasView from './TarjetasView';
import DesignView from './DesignView';
import sql02 from '../../../images/proyectos/all/curso-sql/02-111mil-sql.jpg';
import sql03 from '../../../images/proyectos/all/curso-sql/03-111mil-sql.jpg';
import './ProyectoFonts.css';
import NavView from './NavView';

const CursoSQL = () => {

  const [anchorTop, setAnchorTop] = React.useState('hidden');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.onscroll = () => handleAnimation();
  },[])
  
  const handleAnimation = () => {
    
    if (document.documentElement.scrollTop > 300) {    
      setAnchorTop('visible');
    }
    if (document.documentElement.scrollTop < 299) {    
      setAnchorTop('hidden');
    }
  }

  return (
    <div className="container-fluid" id="AnchorHeader">
      
      {/* Portada BG */}
      <div className="proyectoViewImg"  style={{ background: "url("+sql02+")", backgroundRepeat: 'no-repeat' }}>
        <div className="proyectoView">

          {/* Navegación */}
          <Nav />

          {/* Título + Subtítulo */}
          <div className="headView_group">
            <h1>Curso SQL</h1>
            <h3>Curso básico realizado para compañeros del Programa 111mil Programadores</h3>
          </div>

        </div> {/* -> proyectoView*/}

        {/* Tarjetas */}
        <TarjetasView 
          trabajo = 'Diseño ^ Desarrollo ^ Front-End ^ Back-End'
          contexto = 'Curso básico SQL'
          nacimiento = '2017'
        />
        
        <SobreElProyecto
          subTitulo = '...Sobre el Curso'
          mensaje = 'Debido a la inquietud de muchos de mis compañeros de curso sobre ciertos temas de SQL, decidí realizar este Sitio Web para apoyarlos al momento de rendir el examen. El sitio básicamente organiza los temas y cada tema es un tutorial que grabé y subí a Youtube.'
          mensaje2 = ''
          mensaje3 = ''
          boton = 'Visitar sitio'
          botonDisabled = ''
          urlBoton = 'http://cursosql.solucionaeso.com'
        />

        {/* DISEÑO */}
        <DesignView
          img1 = {sql03}
          alt1 = 'Curso SQL'

          img2 = ''
          alt2 = ''

          img3 = ''
          alt3 = ''
        />

        <NavView 
          anterior = 'proyecto-fitness'
          siguiente = 'proyecto-sap-v1'
        />

        {/* Pie de página */}
        <Footer />

        {/* TOP */}
        <AnchorLink className={anchorTop} href="#AnchorHeader">
          <div className="anchorTop">
            <i className="fas fa-angle-double-up"></i>
          </div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default CursoSQL
