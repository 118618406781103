import React from 'react';

const SobreMi = () => {
  return (
    <div>
      <div className="avatarModal animate__animated animate__bounceIn"></div>
      <div className="cv_p">
          <p>Soy Carlos, me conocen como Charls o Charly..!</p>

          <p>Desde temprana edad, siempre tuve una curiosidad por cómo funcionan las cosas, lo que me llevó a investigar
              y encontrar soluciones a diversas problemáticas. Desde jugar con locomotoras hasta escalar a configuraciones
              de servidores y desarrollar sistemas de manera autodidacta.</p>

          <p>Hoy en día, me apasiona el desarrollo web, donde puedo expresarme y dejar volar mi imaginación para crear
              soluciones a ideas aparentemente imposibles. Me siento fascinado por el código abstracto del backend, que
              da vida a las funcionalidades del frontend y crea sistemas sólidos a través de bases de datos.</p>

          <p>Actualmente me dedico al backend de PHP, pero sigo siendo un aprendiz constante. Mi consejo de vida es nunca
              rendirse, ignorar a quienes intentan limitarte y siempre avanzar para cumplir metas y sueños.</p>

      </div>
    </div>
  )
}

export default SobreMi;